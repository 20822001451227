import { Component, Input  } from '@angular/core';

@Component({
  selector: 'app-pricing-info-modal',
  templateUrl: './pricing-info-modal.component.html',
  styleUrls: ['./pricing-info-modal.component.css']
})
export class PricingInfoModalComponent {
  @Input() data: any = {};
  isOpen = false;

  toggleModal() {
    this.isOpen = !this.isOpen;
  }
}
